
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import _model from '@/static/model/portalCode/batch'

export default {
	components: {},
	setup(props, context) {
		return {
			_model: _model,
			options: reactive({}),
			setting: reactive({
				moduleName: '使用记录',
				searchApi: '/Mar/Market/useRecode',
				moduleType: 'full',
				tableFirstType: 'selection',
			}),
			list: reactive({
				data: [
										
				]
			}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: { }
}
