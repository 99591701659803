
export default {
	
	options: {
		
		state: [
			{ id: 1, label: '正常'},
			{ id: 2, label: '过期'},
			{ id: 3, label: '停用'},
			{ id: 9, label: '已取消'},
		],
		orderType: [
			{ id: 1, label: '实物快递类'},
			{ id: 2, label: '实物自提类'},
			{ id: 3, label: '服务核销类'},
			{ id: 4, label: '三方核销类'},
		],
		serviceType: [
			{ id: 1, label: '商城订单'},
			{ id: 2, label: '旅游订单'},
			{ id: 3, label: '加油订单'},
		],
		classId: [
			{ id: 1, label: '优惠券'},
			{ id: 2, label: '储值卡'},
		]
	}
	
}